import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    singlePending: false,
    workouts: [],
    completeWorkouts: [],
    error: null
}
const strategies = {
    FETCH_WORKOUTS_ERROR: fetchWorkoutsErrorTransformer,
    FETCH_WORKOUTS_PENDING: fetchWorkoutsPendingTransformer,
    FETCH_SINGLE_WORKOUT_PENDING: fetchSingleWorkoutPendingTransformer,
    FETCH_WORKOUTS_SUCCESS: fetchWorkoutsSuccessTransformer,
    FETCH_WORKOUT_SUCCESS: fetchWorkoutSuccessTransformer
};
const workoutsList = createReducer(initialState, strategies);

function fetchWorkoutsErrorTransformer(state, action) {
    return { ...state, pending: false, singlePending:false, error: action.error};
}
function fetchWorkoutsPendingTransformer(state) {
    return { ...state, pending: true, singlePending:false };
}
function fetchSingleWorkoutPendingTransformer(state) {
  return { ...state, singlePending: true, pending: false };
}
function fetchWorkoutsSuccessTransformer(state, action) {
    const debug = false;

    const fetchedWorkoutsString = action.workouts.data;
    if(debug) console.log(`### fetchedWorkoutsString=${JSON.stringify(fetchedWorkoutsString,null,2)}`)

    let inRequestIds = [];
    inRequestIds = JSON.stringify(fetchedWorkoutsString.map(w=>w._id.toString()));
    if(debug) console.log(`### inRequestIds=${inRequestIds}`)

    let stateWorkoutsString = state.workouts;
    if(debug) console.log(`### stateWorkoutsString=${JSON.stringify(stateWorkoutsString,null,2)}`)

    let inStateIds = [];
    inStateIds = JSON.stringify(stateWorkoutsString.map(w=>w._id.toString()));
    if(debug) console.log(`### inStateIds=${inStateIds}`)

    let filteredFetchedWorkoutsString = [];
    let filteredStateWorkoutsString = [];
    
    filteredStateWorkoutsString = stateWorkoutsString.filter(w=>!inRequestIds.includes(w._id.toString()));
    
    if(debug) console.log(`### filteredFetchedWorkoutsString=${JSON.stringify(filteredFetchedWorkoutsString,null,2)}`)
    if(debug) console.log(`### stateWorkoutsString=${JSON.stringify(stateWorkoutsString,null,2)}`)

    let newWorkoutsString = [];
    newWorkoutsString = fetchedWorkoutsString.concat(filteredStateWorkoutsString);
    
    if(debug) console.log(`### after concat newWorkoutsString=${JSON.stringify(newWorkoutsString,null,2)}`)
    return { ...state, pending: false, singlePending:false, workouts: newWorkoutsString };
}

function fetchWorkoutSuccessTransformer(state, action) {
    const debug = false;
    if(debug) console.log(`### action.id=${action.id}`)
    if(debug) console.log(`### action.workout=${JSON.stringify(action.workout)}`)
  
    let workoutString = state.completeWorkouts.filter(t=>t._id !== action.id);
    workoutString.push(action.workout.data);
    return { ...state, pending: false, singlePending:false, completeWorkouts: workoutString };
  }

export default workoutsList

export const getWorkouts = state => state.workoutsList.workouts;
export const getCompleteWorkouts = state => state.workoutsList.completeWorkouts;
export const getWorkoutsPending = state => state.workoutsList.pending;
export const getSingleWorkoutPending = state => state.workoutsList.singlePending;
export const getWorkoutsError = state => state.workoutsList.error;

