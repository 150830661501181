import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    tests: [],
    error: null
}
const strategies = {
    FETCH_TESTS_ERROR: fetchTestsErrorTransformer,
    FETCH_TESTS_PENDING: fetchTestsPendingTransformer,
    FETCH_TESTS_SUCCESS: fetchTestsSuccessTransformer,
    ADD_TEST_SUCCESS: addTestSuccessTransformer,
    UPDATE_TEST_SUCCESS: updateTestSuccessTransformer,
    REMOVE_TEST_SUCCESS: removeTestSuccessTransformer,
    ADD_TEST_RESULT_SUCCESS: addTestResultSuccessTransformer,
    EDIT_TEST_RESULT_SUCCESS: editTestResultSuccessTransformer,
    DELETE_TEST_RESULT_SUCCESS: deleteTestResultSuccessTransformer
};
const testsList = createReducer(initialState, strategies);

function fetchTestsErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchTestsPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchTestsSuccessTransformer(state, action) {
    return { ...state, pending: false, tests: action.tests.data};
}
function addTestSuccessTransformer (state, action){
    let currentTests = state.tests;
    currentTests.unshift(action.test);

    return { ...state, pending: false, tests: currentTests };
}
function updateTestSuccessTransformer (state, action){
    let currentTests = state.tests.filter(t=>t._id.toString() !== action.id.toString());
    currentTests.unshift(action.test);
    return { ...state, pending: false, tests: currentTests };
}
function removeTestSuccessTransformer (state, action){
    let currentTests = state.tests.filter(t=>t._id.toString() !== action.id.toString());
    return { ...state, pending: false, tests: currentTests };
}
function addTestResultSuccessTransformer (state, action){
    let currentTests = state.tests.filter(t=>t._id.toString() !== action.testid.toString());
    let testToAddResult = state.tests.find(t=>t._id.toString() === action.testid.toString());
    testToAddResult.results.push(action.result);
    currentTests.unshift(testToAddResult);
    return { ...state, pending: false, tests: currentTests };
}
function editTestResultSuccessTransformer (state, action){
    let currentTests = state.tests.filter(t=>t._id.toString() !== action.testid.toString());
    let testToEditResult = state.tests.find(t=>t._id.toString() === action.testid.toString());

    let testResults = testToEditResult.results;
    testResults = testResults.filter(r=>r._id.toString() !== action.resultid.toString());
    testResults.push(action.result);
    testToEditResult.results = testResults;

    currentTests.unshift(testToEditResult);
    return { ...state, pending: false, tests: currentTests };
}

function deleteTestResultSuccessTransformer (state, action){
    let currentTests = state.tests.filter(t=>t._id.toString() !== action.testid.toString());
    let testToEditResult = state.tests.find(t=>t._id.toString() === action.testid.toString());

    let testResults = testToEditResult.results;
    testResults = testResults.filter(r=>r._id.toString() !== action.resultid.toString());
    testToEditResult.results = testResults;

    currentTests.unshift(testToEditResult);
    return { ...state, pending: false, tests: currentTests };
}


export default testsList

export const getTests = state => state.testsList.tests;
export const getTestsPending = state => state.testsList.pending;
export const getTestsError = state => state.testsList.error;

