import { createReducer } from './reducer-factory';

const initialState = {}
const strategies = {
    DESTROY_SESSION: destroySessionTransformer
};
const sessionList = createReducer(initialState, strategies);

function destroySessionTransformer(state, action) {
    return { ...state };
}

export default sessionList

