import { createReducer } from './reducer-factory';

const initialState = {
  isDarkMode: false,
};
const strategies = {
  TOGGLE_DARKMODE: toggleDarkModeTransformer
};
const darkmode = createReducer(initialState, strategies);

function toggleDarkModeTransformer(state, action) {
  return { ...state, isDarkMode: action.isDarkMode };
}

export default darkmode