import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    exercises: [],
    error: null
}
const strategies = {
    FETCH_EXERCISES_PENDING: fetchExercisesPendingTransformer, 
    FETCH_EXERCISE_SUCCESS: fetchExerciseSuccessTransformer, 
    FETCH_EXERCISES_ERROR: fetchExercisesErrorTransformer,
    FETCH_EXERCISES_SUCCESS: fetchExercisesSuccessTransformer
};
const exerciseList = createReducer(initialState, strategies);

function fetchExercisesPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchExerciseSuccessTransformer(state, action) {
    let currentExercises = state.exercises.filter(ex=>ex._id!==action.exercise.data._id);
    currentExercises.push(action.exercise.data);
    return { ...state, pending: false, exercises: currentExercises };
}
function fetchExercisesErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchExercisesSuccessTransformer(state, action) {
    return { ...state, pending: false, exercises: action.exercises };
}
export default exerciseList

export const getExercises = state => state.exerciseList.exercises;
export const getExercise = (state, id) => state.exerciseList.exercises.find(ex=>ex._id===id);
export const getExercisesPending = state => state.exerciseList.pending;
export const getExercisesError = state => state.exerciseList.error;