import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    entities: [],
    allEntities: [],
    singleEntity: null,
    error: null
}
const strategies = {
    FETCH_ENTITIES_ERROR: fetchEntitiesErrorTransformer,
    FETCH_ENTITIES_PENDING: fetchEntitiesPendingTransformer,
    FETCH_ENTITIES_SUCCESS: fetchEntitiesSuccessTransformer,
    FETCH_ALL_ENTITIES_SUCCESS:  fetchAllEntitiesSuccessTransformer,
    FETCH_ENTITY_SUCCESS: fetchEntitySuccessTransformer,
    CLEAR_SINGLE_ENTITY: clearSingleEntityTransformer
};
const entitiesList = createReducer(initialState, strategies);

function fetchEntitiesErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchEntitiesPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchEntitiesSuccessTransformer(state, action) {
    return { ...state, pending: false, singleEntity:null, entities: action.entities.data };
}
function fetchAllEntitiesSuccessTransformer(state, action) {
    return { ...state, pending: false, singleEntity:null, allEntities: action.entities.data, singleRequested:false };
}
function fetchEntitySuccessTransformer(state, action) { 
    return { ...state, pending: false, singleEntity:action.entity.data, singleRequested:true};
}
function clearSingleEntityTransformer(state,action){
    return { ...state, singleEntity:null, entities:null, singleRequested:false};
}

export default entitiesList

export const getAllEntities = state => state.entitiesList.allEntities;
export const getEntities = state => state.entitiesList.entities;
export const getEntity = (state, id) => state.entitiesList.allEntities.find(t=>t._id === id);
export const getSingleEntity = state => state.entitiesList.singleEntity;
export const getEntitiesPending = state => state.entitiesList.pending;
export const getEntitiesError = state => state.entitiesList.error;


