import moment from 'moment';

const areDatesEqual = (date1, date2) => {
  const debug = false;
  if (debug) console.log(`### date1=${date1}`);
  if (debug) console.log(`### date2=${date2}`);

  const date1Date = moment(date1).set({hour:0,minute:0,second:0,millisecond:0});
  if (debug) console.log(`### date1Date=${date1Date}`);
  const date2Date = moment(date2).set({hour:0,minute:0,second:0,millisecond:0});
  if (debug) console.log(`### date2Date=${date2Date}`);

  const diff = date1Date.diff(date2Date, 'days');
  if (debug) console.log(`### diff=${diff}`);
  return diff === 0;
}

const areDatesByTSEqual = (date1TS, date2TS) => {
  const debug = false;
  const date1 = moment.unix(date1TS/1000).set({hour:0,minute:0,second:0,millisecond:0});
  if(debug) console.log(`### date1:${date1}`);
  const readableDate1 = date1.format("YYYY-MM-DD");
  if(debug) console.log(`### readableDate1:${readableDate1}`);
  const date2 = moment.unix(date2TS/1000).set({hour:0,minute:0,second:0,millisecond:0});
  if(debug) console.log(`### date2:${date2}`);
  const readableDate2 = date2.format("YYYY-MM-DD");
  if(debug) console.log(`### readableDate2:${readableDate2}`);

  return date1.toString() === date2.toString()
}

const getWeekForDate = dateTS => {
  const debug = false;
  let diff = moment.unix(Date.now()/1000).set({hour:0,minute:0,second:0,millisecond:0}) - moment.unix(dateTS/1000).set({hour:0,minute:0,second:0,millisecond:0});
  if(debug) console.log(`### diff:${diff}`);
  diff /= (1000 * 60 * 60 * 24 * 7);
  return Math.abs(Math.round(diff));
}
 
const getISOFromTimestamp = ts => {
  return moment(moment.unix(ts/1000).toISOString()).format("YYYY-MM-DD");
}

const formatYMD = isoDate => {
  return moment(isoDate).format("YYYY-MM-DD");
}

const isTSToday = dateTS =>{
  const debug = false;
  const date = moment.unix(dateTS/1000).set({hour:0,minute:0,second:0,millisecond:0});
  if(debug) console.log(`### date:${date}`);
  const readableDate = date.format("YYYY-MM-DD");
  if(debug) console.log(`### readableDate:${readableDate}`);
  const todayDate = moment().set({hour:0,minute:0,second:0,millisecond:0});
  if(debug) console.log(`### todayDate:${todayDate}`);
  const readableTodayDate = todayDate.format("YYYY-MM-DD");
  if(debug) console.log(`### readableTodayDate:${readableTodayDate}`);
  if(debug) console.log(`### returning:${date.toString() === todayDate.toString()}`);
  return date.toString() === todayDate.toString()
}

export default {
  getISOFromTimestamp,
  areDatesEqual,
  areDatesByTSEqual,
  formatYMD,
  isTSToday,
  getWeekForDate
};