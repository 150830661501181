import { createReducer } from './reducer-factory';
import dateHelper from 'helpers/dateHelper';
import moment from 'moment';

const debug=false
const todayDate = moment().set({hour:0,minute:0,second:0,millisecond:0});

const initialState = {
    pending: false,
    mealplans: [],
    error: null
}
const strategies = {
    FETCH_MEALPLANS_ERROR: fetchMealplansErrorTransformer,
    FETCH_MEALPLANS_PENDING: fetchMealplansPendingTransformer,
    FETCH_MEALPLANS_SUCCESS: fetchMealplansSuccessTransformer,
    ADD_MEALPLAN_SUCCESS: addMealplanSuccessTransformer,
    UPDATE_MEALPLAN_SUCCESS: updateMealplanSuccessTransformer,
    REMOVE_MEALPLAN_SUCCESS: removeMealplanSuccessTransformer,
};
const mealplansList = createReducer(initialState, strategies);

function fetchMealplansErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchMealplansPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchMealplansSuccessTransformer(state, action) {
    return { ...state, pending: false, mealplans: action.mealplans.data };
}
function addMealplanSuccessTransformer (state, action){
    let currentMealplans = state.mealplans;
    currentMealplans.push(action.mealplan);

    return { ...state, pending: false, mealplans: currentMealplans };
}
function updateMealplanSuccessTransformer (state, action){
    let currentMealplans = state.mealplans.filter(wi=>wi._id.toString() !== action.id.toString());
    currentMealplans.push({
        ...action.mealplan,
        _id: action.id.toString()
    });

    return { ...state, pending: false, mealplans: currentMealplans };
}
function removeMealplanSuccessTransformer (state, action){
    let currentMealplans = state.mealplans.filter(wi=>wi._id.toString() !== action.id.toString());
    return { ...state, pending: false, mealplans: currentMealplans };
}

export default mealplansList

export const getMealplans = state => state.mealplansList.mealplans;
export const getMealplansPending = state => state.mealplansList.pending;
export const getMealplansError = state => state.mealplansList.error;



