import { combineReducers } from 'redux'
import darkmode from './darkmode'
import productList from './products'
import programList from './programs'
import exerciseList from './exercises'
import resultList from './results'
import orderList from './orders'
import chargeList from './charges'
import sessionList from './session'
import entitiesList from './entities'
import weightinsList from './weightins'
import mealsList from './meals'
import ingredientsList from './ingredients'
import workoutsList from './workouts'
import mealplansList from './mealplans'
import testsList from './tests'
import { localizeReducer } from "react-localize-redux";

const appReducer = combineReducers({
  localize: localizeReducer,
  darkmode,
  productList,
  programList,
  exerciseList,
  sessionList,
  entitiesList,
  orderList,
  chargeList,
  weightinsList,
  mealsList,
  mealplansList,
  ingredientsList,
  testsList,
  resultList,
  workoutsList
})

const rootReducer = (state, action) => {   
  if(action.type === 'DESTROY_SESSION')
    state = {
      localize: state.localize
    };
  
  return appReducer(state, action);
};

export default rootReducer;