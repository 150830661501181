exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-en-challenge-jsx": () => import("./../../../src/pages/en/challenge.jsx" /* webpackChunkName: "component---src-pages-en-challenge-jsx" */),
  "component---src-pages-en-coaches-jsx": () => import("./../../../src/pages/en/coaches.jsx" /* webpackChunkName: "component---src-pages-en-coaches-jsx" */),
  "component---src-pages-en-index-jsx": () => import("./../../../src/pages/en/index.jsx" /* webpackChunkName: "component---src-pages-en-index-jsx" */),
  "component---src-pages-en-privacy-policy-jsx": () => import("./../../../src/pages/en/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-en-privacy-policy-jsx" */),
  "component---src-pages-en-terms-conditions-jsx": () => import("./../../../src/pages/en/terms-conditions.jsx" /* webpackChunkName: "component---src-pages-en-terms-conditions-jsx" */),
  "component---src-pages-fr-challenge-jsx": () => import("./../../../src/pages/fr/challenge.jsx" /* webpackChunkName: "component---src-pages-fr-challenge-jsx" */),
  "component---src-pages-fr-coaches-jsx": () => import("./../../../src/pages/fr/coaches.jsx" /* webpackChunkName: "component---src-pages-fr-coaches-jsx" */),
  "component---src-pages-fr-index-jsx": () => import("./../../../src/pages/fr/index.jsx" /* webpackChunkName: "component---src-pages-fr-index-jsx" */),
  "component---src-pages-fr-privacy-policy-jsx": () => import("./../../../src/pages/fr/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-fr-privacy-policy-jsx" */),
  "component---src-pages-fr-terms-conditions-jsx": () => import("./../../../src/pages/fr/terms-conditions.jsx" /* webpackChunkName: "component---src-pages-fr-terms-conditions-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

