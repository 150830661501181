import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    products: [],
    error: null
}
const strategies = {
    FETCH_PRODUCTS_PENDING: fetchProductsPendingTransformer,
    FETCH_PRODUCTS_SUCCESS: fetchProductsSuccessTransformer,
    FETCH_PRODUCT_SUCCESS: fetchProductSuccessTransformer,
    FETCH_PRODUCTS_ERROR: fetchProductsErrorTransformer
};
const productList = createReducer(initialState, strategies);

function fetchProductsPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchProductsSuccessTransformer(state, action) {
    return { ...state, pending: false, products: action.products.data };
}
function fetchProductSuccessTransformer(state, action) {
    let allProducts = state.products;
    allProducts.push(action.product.data);
    return { ...state, pending: false, products: allProducts};
}
function fetchProductsErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
export default productList

export const getProducts = state => state.productList.products;
export const getProductsPending = state => state.productList.pending;
export const getProductsError = state => state.productList.error;