import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    orders: [],
    error: null
}
const strategies = {
    FETCH_ORDERS_PENDING: fetchOrdersPendingTransformer,
    FETCH_ORDERS_SUCCESS: fetchOrdersSuccessTransformer,
    FETCH_ORDERS_ERROR: fetchOrdersErrorTransformer
};
const orderList = createReducer(initialState, strategies);

function fetchOrdersPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchOrdersSuccessTransformer(state, action) {
    return { ...state, pending: false, orders: action.orders };
}
function fetchOrdersErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
export default orderList

export const getOrders = state => state.orderList.orders;
export const getOrdersPending = state => state.orderList.pending;
export const getOrdersError = state => state.orderList.error;