import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    weightins: [],
    error: null
}
const strategies = {
    FETCH_WEIGHTINS_ERROR: fetchWeightinsErrorTransformer,
    FETCH_WEIGHTINS_PENDING: fetchWeightinsPendingTransformer,
    FETCH_WEIGHTINS_SUCCESS: fetchWeightinsSuccessTransformer,
    ADD_WEIGHTIN_SUCCESS: addWeighinSuccessTransformer,
    UPDATE_WEIGHTIN_SUCCESS: updateWeighinSuccessTransformer,
    REMOVE_WEIGHTIN_SUCCESS: removeWeighinSuccessTransformer,
};
const weightinsList = createReducer(initialState, strategies);

function fetchWeightinsErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchWeightinsPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchWeightinsSuccessTransformer(state, action) {
    return { ...state, pending: false, weightins: action.weightins.data };
}
function addWeighinSuccessTransformer (state, action){
    let currentWeightIns = state.weightins;
    currentWeightIns.push(action.weightin);

    return { ...state, pending: false, weightins: currentWeightIns };
}
function updateWeighinSuccessTransformer (state, action){
    let currentWeightIns = state.weightins.filter(wi=>wi._id.toString() !== action.id.toString());
    currentWeightIns.push(action.weightin);
    return { ...state, pending: false, weightins: currentWeightIns };
}
function removeWeighinSuccessTransformer (state, action){
    let currentWeightIns = state.weightins.filter(wi=>wi._id.toString() !== action.id.toString());
    return { ...state, pending: false, weightins: currentWeightIns };
}

export default weightinsList

export const getWeightins = state => state.weightinsList.weightins;
export const getWeightinsPending = state => state.weightinsList.pending;
export const getWeightinsError = state => state.weightinsList.error;

