import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    ingredients: [],
    error: null
}
const strategies = {
    FETCH_INGREDIENTS_ERROR: fetchIngredientsErrorTransformer,
    FETCH_INGREDIENTS_PENDING: fetchIngredientsPendingTransformer,
    FETCH_INGREDIENTS_SUCCESS: fetchIngredientsSuccessTransformer,
    ADD_INGREDIENTS_SUCCESS: addIngredientsSuccessTransformer
};
const ingredientsList = createReducer(initialState, strategies);

function fetchIngredientsErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchIngredientsPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchIngredientsSuccessTransformer(state, action) {
    return { ...state, pending: false, ingredients: action.ingredients.data };
}
function addIngredientsSuccessTransformer (state, action){
    let currentIngredients = state.ingredients;
    currentIngredients = currentIngredients.concat(action.ingredients);
    return { ...state, pending: false, ingredients: currentIngredients };
}

export default ingredientsList

export const getIngredients = state => state.ingredientsList.ingredients;
export const getIngredientsPending = state => state.ingredientsList.pending;
export const getIngredientsError = state => state.ingredientsList.error;

