import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    programs: [],
    error: null
}
const strategies = {
    FETCH_PROGRAMS_PENDING: fetchProgramsPendingTransformer, 
    FETCH_PROGRAMS_SUCCESS: fetchProgramsSuccessTransformer, 
    FETCH_PROGRAMS_ERROR: fetchProgramsErrorTransformer,
    FETCH_PROGRAM_SUCCESS: fetchProgramSuccessTransformer 
};
const programList = createReducer(initialState, strategies);

function fetchProgramsPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchProgramsSuccessTransformer(state, action) {
    return { ...state, pending: false, programs: action.programs.data };
}
function fetchProgramsErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchProgramSuccessTransformer(state, action) {
    let programString = state.programs.filter(t=>t._id !== action.program._id);
    programString.push(action.program);
    return { ...state, pending: false, programs: programString };
}
export default programList

export const getPrograms = state => state.programList.programs;
export const getProgramsPending = state => state.programList.pending;
export const getProgramsError = state => state.programList.error;