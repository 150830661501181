import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    charges: [],
    error: null
}
const strategies = {
    FETCH_CHARGES_PENDING: fetchChargesPendingTransformer,
    FETCH_CHARGES_SUCCESS: fetchChargesSuccessTransformer,
    FETCH_CHARGES_ERROR: fetchChargesErrorTransformer
};
const chargeList = createReducer(initialState, strategies);

function fetchChargesPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchChargesSuccessTransformer(state, action) {
    return { ...state, pending: false, charges: action.charges };
}
function fetchChargesErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
export default chargeList

export const getCharges = state => state.chargeList.charges;
export const getChargesPending = state => state.chargeList.pending;
export const getChargesError = state => state.chargeList.error;