import React from 'react';
import { Provider } from 'react-redux';
import { createStore as reduxCreateStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers'
import thunk from 'redux-thunk';

import { LocalizeProvider } from "react-localize-redux";
import Main from './Main'

const middlewares = [thunk];
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares)
);
const createStore = () => reduxCreateStore(rootReducer, enhancer);
const store = createStore();

export default ({ element }) => (
  <Provider store={store}>
    <LocalizeProvider store={store}>
      <Main>
        {element}
      </Main>
    </LocalizeProvider>
  </Provider>
);
