import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import globalTranslations from "../translations/global.json";

class Main extends React.Component {
  constructor(props) {
    super(props);

    const supportedLangs = [
      { name: "English", code: "en" },
      { name: "Français", code: "fr" }
    ];

    this.props.initialize({
      languages: supportedLangs,
      translation: globalTranslations,
      options: { 
        renderToStaticMarkup,
        defaultLanguage: "en"
      }
    });
  }

  render() {
    return (
      <>
      {this.props.children}
      </>
    )
  }
}

export default withLocalize(Main);
