import { createReducer } from './reducer-factory';

const initialState = {
    pending: false,
    results: [],
    error: null,
    tablePending: false,
    resultTable: null,
    tableError: null
}
const strategies = {
    FETCH_RESULTS_PENDING: fetchResultsPendingTransformer, 
    FETCH_RESULTS_SUCCESS: fetchResultsSuccessTransformer,  
    FETCH_RESULTS_ERROR: fetchResultsErrorTransformer,
    FETCH_RESULT_TABLE_PENDING: fetchResultTablePendingTransformer, 
    FETCH_RESULT_TABLE_SUCCESS: fetchResultTableSuccessTransformer,
    FETCH_RESULT_TABLE_ERROR: fetchResultTableErrorTransformer,
    ADD_RESULT_SUCCESS: addResultSuccessTransformer,
    UPDATE_RESULT_SUCCESS: updateResultSuccessTransformer,
    REMOVE_RESULT_SUCCESS: removeResultSuccessTransformer
};
const resultList = createReducer(initialState, strategies);

function fetchResultsPendingTransformer(state) {
    return { ...state, pending: true };
}
function fetchResultsSuccessTransformer(state, action) {
    return { ...state, pending: false, results: [ ...state.results, ...action.results.data] };
}
function fetchResultsErrorTransformer(state, action) {
    return { ...state, pending: false, error: action.error};
}
function fetchResultTablePendingTransformer(state) {
    return { ...state, tablePending: true };
}
function fetchResultTableSuccessTransformer(state, action) {
    return { ...state, tablePending: false, resultTable: action.resultTable.data };
}
function fetchResultTableErrorTransformer(state, action) {
    return { ...state, tablePending: false, tableError: action.error};
}
function addResultSuccessTransformer (state, action){
    let currentResults = state.results;
    currentResults.unshift(action.result);
    return { ...state, pending: false, results: currentResults };
}
function updateResultSuccessTransformer (state, action){
    let currentResults = state.results.filter(t=>t._id.toString() !== action.id.toString());
    currentResults.unshift(action.result);

    return { ...state, pending: false, results: currentResults };
}
function removeResultSuccessTransformer (state, action){
    let currentResults = state.results.filter(t=>t._id.toString() !== action.id.toString());
    return { ...state, pending: false, results: currentResults };
}
export default resultList

export const getResults = (state, programId) => {
    if(programId) return state.resultList.results.filter(res=>res.program===programId);
    else return state.resultList.results;
}
export const getResultsPending = state => state.resultList.pending;
export const getResultsError = state => state.resultList.error;
export const getResultTable = state => state.resultList.resultTable;
export const getResultTablePending = state => state.resultList.tablePending;
export const getResultTableError = state => state.resultList.tableError;